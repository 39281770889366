.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 85vh;
}

.button {
    font-family: var(--font-family-main);
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: none;
    background-color: var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 5px;
    padding: 10px;
    width: 300px;
}