.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
    position: relative;
}

.fullscreen_btn {
    position: absolute;
    left: 10px;
    top: 10px;
    background: var(--secondary-color-main);
    border: none;
    z-index: 100;
    cursor: pointer;
}

.fullscreen_btn_icon {
    width: 30px;
 }

.settings_btn {
    background: var(--secondary-color-main);
    border: none;
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.settings_btn_icon {
    width: 30px;
}

.fullscreen_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    gap: 20px;

}

.fullscreen_mode {
    background-image: url("../../logo.png");
    background-color: rgb(255, 255, 255);
    background-position: center;
    background-size: 350px;
}

.button_container {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 30px;
    gap: 10px;;
}

.button {
    width: 150px;
    padding: 5px;
    font-family: var(--font-family-main);
    font-size: 25px;
    font-weight: bold;
    color: var(--primary-color);
    background-color: var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 5px;
    cursor: pointer;
    background-position: center;
    transition: background 0.8s;
}

.button:hover {
    background: var(--secondary-color-main) radial-gradient(circle, transparent 1%, var(--warning-color) 1%) center/15000%;
  }

.button:active {
    background-color: var(--secondary-color-main);
    background-size: 100%;
    transition: background 0s;
}