@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@1&family=Kalnia:wght@200&display=swap');

:root {
    /* Font Family */
    --font-family-main:  'DM Serif Display', serif;
    --font-family-secondary: 'Kalnia', serif;

    /* Colors */
    --primary-color: #333333;
    --secondary-color-main: #F7FFF7;
    --warning-color: #FF6B6B;
}