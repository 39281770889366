.err_msg_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100px;
    min-width: 350px;
    background-color: var(--secondary-color-main);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    z-index: 100;
}

.err_msg {
    padding: 5px;
    color: red;font-family: var(--font-family-secondary);
    color: var(--warning-color);
}

.err_btn {
    position: absolute;
    background-color: none ;
    top:5px;
    right: 10px;
    border: none;
    cursor: pointer;
}

.err_btn h2 {
    margin: 0;
}