.fullscreen_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    gap: 20px;

}

.fullscreen_btn {
    position: absolute;
    left: 10px;
    top: 10px;
    background: var(--secondary-color-main);
    border: none;
    z-index: 100;
    cursor: pointer;
}

.fullscreen_btn_icon {
    width: 30px;
}

.loader_div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
}