@import "./Variables.module.css";

.footer {
    padding: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--secondary-color-main);
    opacity: 0.8;
    z-index: 1;
}

.footer_text {
    font-family: var(--font-family-secondary);
    font-weight: bold;
    z-index: 100;
    color: var(--primary-color);
    font-weight: bold;
}