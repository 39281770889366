.container {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-width: 350px;
    min-height: 300px;
    background-color:var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 10px;
    box-shadow: 0 0 23px var(--secondary-color-main);
    padding: 5px;
    z-index: 100;
}

.settings_cont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.input_cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
}

.label {
    font-family: var(--font-family-main);
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
}

.select {
    height: 30px;
    font-weight: bold;
    color: var(--primary-color);
}

.button {
    width: 150px;
    padding: 10px;
    font-family: var(--font-family-main);
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
    background-color: white;
    border: 1px solid var(--warning-color);
    border-radius: 5px;
    cursor: pointer;
}