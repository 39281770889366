.App {
  text-align: center;
  background-image: url("./logo.png");
  background-repeat: repeat;
  background-position: center;
  background-size: 350px;
}

.Loader_Cont {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
