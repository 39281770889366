@import "./Variables.module.css";

.div_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    width: 100vw;
    height: 100%;
    gap: 10px;
}

.collections {
    height: 50px;
    font-family: var(--font-family-primary);
    color: var(--primary-color);
    font-weight: bold;
    padding: 5px;
}

.collections_btn {
    height: 50px;
    font-family: var(--font-family-primary);
    color: var(--primary-color);
    font-weight: bold;
    background-color: var(--secondary-color-main);
    border-radius: 5px;
}

.download_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85vh;
    padding: 10px;
}

.view_btn_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 10px;
    min-height: 250px;
}

.text {
    font-family: var(--font-family-main);
    color: var(--primary-color);
    font-weight: bold;
    font-size: 25px;
    padding: 10px;
}

.view_btn {
    font-family: var(--font-family-main);
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    padding: 10px;
    cursor: pointer;
    border: 2px solid var(--warning-color);
    border-radius: 5px;
}

.photo_div {
    background: white;
}

.photos_cont {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 1200px;
}

.li {
    list-style: none;
    position: relative;
}

.photo {
    width: auto;
    max-width: 350px;
    height: auto;
}

.download_button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    cursor: pointer;
}

.download_icon {
    width: 24px;
    height: 24px;
}