.settings_cont {
    position: absolute;
    top: 50%;
    right: 10%;
    z-index: 100;
    width: 300px;
    height: 200px;
    background-color: aliceblue;
    border-radius: 5px;
    padding: 10px;
}

.brightness_cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}