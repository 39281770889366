.take_photo_container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.camera {
    position: relative;
}

.video{
    height: 85vh;
    width: 100%;
    object-fit: cover;
}

.fullscreen_video {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.capture_btn {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    padding: 10px;
    font-family: var(--font-family-main);
    font-size: 25px;
    font-weight: bold;
    color: var(--primary-color);
    background-color: var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 5px;
    cursor: pointer;
    background-position: center;
    transition: background 0.8s;
}

.capture_btn:hover {
    background: var(--secondary-color-main) radial-gradient(circle, transparent 1%, var(--warning-color) 1%) center/15000%;
  }

.capture_btn:active {
    background-color: var(--secondary-color-main);
    background-size: 100%;
    transition: background 0s;
}

.frame_landscape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99%;
}

.frame_orientation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.display_hide{
    display: none;
}

.preview_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.canvas {
    display: none;
    width: 100%;
    height: 100%;
}

.photo {
    width: 100%;
    height: 100%;
}

@media  screen and (max-width: 600px) {
    .video_orientation {
        height: unset;
    }
}