@import "./Variables.module.css";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 350px;
    height: 300px;
    background-color: white;
    border: 2px solid var(--warning-color);
    border-radius: 10px;
    box-shadow: 0 0 23px var(--secondary-color-main);
    z-index: 100;
}

.input_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;

}

.label {
    font-family: var(--font-family-main);
    font-size: 25px;
    font-weight: bold;
    color: var(--primary-color);
}

.input {
    width: 95%;
    height: 30px;
    font-family: var(--font-family-secondary);
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-color);
    padding: 0 5px;
    border: 2px solid #57201b;
    border-radius: 5px;
}

.button {
    width: 150px;
    padding: 10px;
    font-family: var(--font-family-main);
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
    background-color: var(--secondary-color-main);
    border: 1px solid var(--warning-color);
    border-radius: 5px;
    cursor: pointer;
}

@media  screen and (max-width: 600px) {
    .label {
        font-size: 18px;
    }
    .input {
        font-size: 18px;
    }
}
