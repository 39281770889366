@import "./Variables.module.css";

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding: 15px;
    position: relative;
}

.background {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--secondary-color-main);
    opacity: 0.8;
    z-index: 1;
}

.nav {
    font-family: var(--font-family-main);
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
    margin: 0;
    color: var(--primary-color);
    padding: 5px;
    z-index: 100;
}

.nav.active {
    text-decoration: underline;
}

.nav:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color-main);
}

.button {
    font-family: var(--font-family-main);
    font-weight: bold;
    color: var(--primary-color);
    font-size: 25px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 100;
    padding-right: 15px;
}

.button:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color-main);
}

@media  screen and (max-width: 600px) {
    .header {
        gap: 10px;
    }
    .nav {
        font-size: 18px;
    }

    .button {
        font-size: 18px;
    }
}