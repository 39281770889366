.preview_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.portrait {
    height: 85vh;
}

.canvas {
    display: none;
    width: 100%;
    height: 100%;
}

.photo {
    width: 100%;
    height: 100%;
}

.next_icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.prev_icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: transparent;
    border: none;
    rotate: 180;
    cursor: pointer;
}

.prev_icon img{
    transform: rotate(180deg);
}

.hide_icon {
    display: none;
}

.button_container {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    gap: 10px;;
}

.button {
    width: 150px;
    padding: 5px;
    font-family: var(--font-family-main);
    font-size: 25px;
    font-weight: bold;
    color: var(--primary-color);
    background-color: var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 5px;
    cursor: pointer;
    background-position: center;
    transition: background 0.8s;
}

.button:hover {
    background: var(--secondary-color-main) radial-gradient(circle, transparent 1%, var(--warning-color) 1%) center/15000%;
  }

.button:active {
    background-color: var(--secondary-color-main);
    background-size: 100%;
    transition: background 0s;
}

@media screen and (max-width: 500px) {
    .portrait {
        height: unset;
    }
}