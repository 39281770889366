.counter_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.h4{
    color: var(--secondary-color-main);
    font-family: var(--font-family-secondary);
}

.timer {
    animation: zoom 1s infinite;
}

@keyframes zoom {
    0%{
        -ms-transform: scale(0.1);
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
    }
       
    100%{
        -ms-transform: scale(4.5);
        -webkit-transform: scale(4.5);
        transform: scale(4.5);
    }
}

.cheese {
    -ms-transform: scale(3.5);
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
}
