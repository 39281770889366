.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 900px;
}
.form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    background-color: var(--secondary-color-main);
    border: 2px solid var(--warning-color);
    border-radius: 10px;
    min-height: 250px;
    width: 350px;
}

.input_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
}

.label {
    font-family: var(--font-family-main);
    font-size: 25px;
    font-weight: bold;
    color: var(--primary-color);
}

.input {
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: bold;
    color: var(--warning-color);
    width: 95%;
    border: 2px solid var(--warning-color);
    border-radius: 5px;
}

.error_text {
    font-family: var(--font-family-main);
    font-weight: bold;
    color: var(--warning-color);
    margin: 0;
}

.enter_btn {
    font-family: var(--font-family-main);
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
    padding: 10px;
    background-color: white;
    border: 2px solid var(--warning-color);
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}