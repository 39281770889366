.loader_primary {
    border: 16px solid var(--secondary-color-main);
    border-radius: 50%;
    border-top: 16px solid var(--primary-color);
    border-bottom: 16px solid var(--primary-color);
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}